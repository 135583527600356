<template>
<div class="col-md-10">
    <div class="col-md=12"><center>
        <select id="metric" @change="metricChange($event)">
                <option value="NDV">NDV</option>
                <option value="Deposits">Deposits</option>
                <option value="Daily Available">Daily Available</option>
                <option value="Rewards">Rewards</option>
                <option value="Directs">Directs</option>
                <option value="Total Team">Total Team</option>
                <option value="Airdrops Sent">Airdrops Sent</option>
                <option value="Airdrops Received">Aidrops Received</option>
        </select></center>
    </div>
    <div class="row">
        <div v-for="group in trans_group" :key="group.labels" class="col-md-6">
          <LineChart :chartData="group"/>
        </div>
    </div>
</div>

</template>

<script>
import LineChart from '../components/lineGraph.vue'
import { db } from '../firebaseDb';

export default {
  name: 'App',
  components: { LineChart },
  data() {
      return {
        chartData: [],
        trans_group: {},
        metric: "NDV"
      }
      
  },
      created() {
            this.getData();
        },
        methods: {
            metricChange(e) {
                this.metric = e.target.value
                this.trans_group = {}
                console.log(this.metric)
                this.getData()
            },
            getData() {
                //const this.trans_group = [];
                var dataD = []
                var trans_data = {}
                var last_ndv = 0
                var last_rewards = 0
                var ndv_diff = 0
                var rewards_diff = 0
                var metric_value
                var two_weeks_ago = new Date(Date.now() - 727 * 3600 * 1000).toISOString()
                console.log(two_weeks_ago)
                db.collection('Transactions').where('date', '>', two_weeks_ago).orderBy('date', 'asc').orderBy('name').onSnapshot((snapshotChange) => {
                this.Transactions = [];
                snapshotChange.forEach((doc) => {
                    let date = new Date(doc.data().date)
                    let month = new Date(doc.data().date).getMonth() + 1;
                    let datestring = date.getFullYear() + "/" + month + "/" + date.getDate() + " " + date.getHours() + ":" + date.getMinutes().toString()
                    let name = doc.data().name
                    // console.log("name" in this.Transactions)
                    
                    
                    ndv_diff = doc.data().ndv - last_ndv
                    rewards_diff = doc.data().rewards - last_rewards
                    
                    ndv_diff = ndv_diff.toLocaleString()
                    rewards_diff = rewards_diff.toLocaleString()
                    
                    if (this.metric == "NDV") {
                        metric_value = doc.data().ndv
                    }
                    if (this.metric == "Deposits") {
                        metric_value = doc.data().deposits
                    }                    
                    if (this.metric == "Daily Available") {
                        metric_value = doc.data().available
                    } 
                    if (this.metric == "Rewards") {
                        metric_value = doc.data().rewards
                    }                     
                    if (this.metric == "Directs") {
                        metric_value = doc.data().directs
                    }                     
                    if (this.metric == "Total Team") {
                        metric_value = doc.data().total_team
                    }                     
                    if (this.metric == "Airdrops Sent") {
                        metric_value = doc.data().airdrop_sent
                         console.log("Here2", metric_value)
                    }                     
                    if (this.metric == "Airdrops Received") {
                        metric_value = doc.data().airdrop_received
                        console.log("Here", metric_value)
                    }                     

                    // var show_wallets = ["DRIP 1", "DRIP 2", "DRIP 3"]

                    // if (show_wallets.includes(name) && name != undefined) {
                    if (name != undefined) {
                        if (!(name in this.trans_group)) {
                                this.trans_group[name] = []
                                // console.log("Hi!", this.Transactions[name])
                                last_ndv = 0
                                last_rewards = 0
                            } 

                            if(this.trans_group[name].labels == null) {
                                this.trans_group[name] = {
                                    labels: [ datestring ],
                                    datasets: [{
                                        label: name+" "+this.metric,
                                        backgroundColor: 'blue',
                                        borderColor: 'blue',
                                        data: [ metric_value ]
                                }]
                            }
                            }
                            else {
                                
                                this.trans_group[name].labels.push(datestring)
                                //Figure out how to push to dataset.data.
                                dataD = this.trans_group[name].datasets[0].data
                                dataD.push(metric_value)
                                this.trans_group[name].datasets["data"] = dataD
                                dataD = []
                            }
                    }
                     trans_data = {
                        key: doc.id,
                        name: doc.data().name,
                        date: datestring,
                        action: doc.data().action,
                        deposits: doc.data().deposits,
                        available: doc.data().available,
                        rewards: doc.data().rewards,
                        airdrop_sent: doc.data().airdrop_sent,
                        airdrop_received: doc.data().airdrop_received,
                        directs: doc.data().directs,
                        total_team: doc.data().total_team,
                        ndv: doc.data().ndv,
                        ndv_diff: ndv_diff,
                        rewards_diff: rewards_diff
                    };
                    
                                        
                    last_ndv = doc.data().ndv
                    last_rewards = doc.data().rewards
                    
                    
                    // console.log(this.trans_group);
                    this.Transactions.push(trans_data);
                        //  console.log(date, this.Transactions[name])
                    
                    })
                })
                
                // console.log("hi", this.Transactions, trans_data)
            },

        }

  
  
}
</script>