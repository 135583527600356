<template>
<div class="col-md-10">
    <div class="col-md=12"><center>
        <select id="metric" @change="metricChange($event)">
                <option value="Deposits">Deposits</option>
                <option value="NDV">NDV</option>
                <option value="Daily Available">Daily Available</option>
                <option value="Rewards">Rewards</option>
                <option value="Directs">Directs</option>
                <option value="Total Team">Total Team</option>
                <option value="Airdrops Sent">Airdrops Sent</option>
                <option value="Airdrops Received">Aidrops Received</option>
        </select></center>
    </div>
    <div class="row" style="align:center">
        <div v-for="group in trans_group" :key="group.labels" class="col-md-12">
          <LineChart :chartData="group"/>
        </div>
    </div>
    
</div>

</template>

<script>
import LineChart from '../components/lineGraph.vue'
import { db } from '../firebaseDb';

export default {
  name: 'App',
  components: { LineChart },
  data() {
      return {
        chartData: [],
        trans_group: {},
        metric: "Deposits"
      }
      
  },
      created() {
            this.getData();
        },
        methods: {
            metricChange(e) {
                this.metric = e.target.value
                this.trans_group = {}
                console.log(this.metric)
                this.getData()
            },
            getData() {
                //const this.trans_group = [];
                var dataD = []
                var last_ndv = 0
                var last_rewards = 0
                var ndv_diff = 0
                var rewards_diff = 0
                var metric_value
                var total_metric = 0
                var lastdate
                var two_weeks_ago = new Date(Date.now() - 6000 * 3600 * 1000).toISOString() // 727 = 2 weeks
                console.log(two_weeks_ago)
                db.collection('OthersDRIPTransactions').where('date', '>', two_weeks_ago).where('member', '==', 'Bob').orderBy('date', 'asc').onSnapshot((snapshotChange) => {
                this.Transactions = [];
                snapshotChange.forEach((doc) => {
                    let date = new Date(doc.data().date)
                    let month = new Date(doc.data().date).getMonth() + 1;
                    let datestring = date.getFullYear() + "/" + month + "/" + date.getDate().toString()
                    let name = doc.data().name
                    // console.log("name" in this.Transactions)
                    
                    
                    ndv_diff = doc.data().ndv - last_ndv
                    rewards_diff = doc.data().rewards - last_rewards
                    
                    ndv_diff = ndv_diff.toLocaleString()
                    rewards_diff = rewards_diff.toLocaleString()
                    
                    if (this.metric == "NDV") {
                        metric_value = doc.data().ndv
                    }
                    if (this.metric == "Deposits") {
                        metric_value = doc.data().deposits
                    }                    
                    if (this.metric == "Daily Available") {
                        metric_value = doc.data().available
                    } 
                    if (this.metric == "Rewards") {
                        metric_value = doc.data().rewards
                    }                     
                    if (this.metric == "Directs") {
                        metric_value = doc.data().directs
                    }                     
                    if (this.metric == "Total Team") {
                        metric_value = doc.data().total_team
                    }                     
                    if (this.metric == "Airdrops Sent") {
                        metric_value = doc.data().airdrop_sent
                         console.log("Here2", metric_value)
                    }                     
                    if (this.metric == "Airdrops Received") {
                        metric_value = doc.data().airdrop_received
                        console.log("Here", metric_value)
                    }                     

                    // var show_wallets = ["DRIP 1", "DRIP 2", "DRIP 3"]
                    
                    if (datestring == lastdate) {
                    total_metric = total_metric + Number(metric_value)
                    } else {
                        if (name != undefined) {
                            if (!('Combined Data' in this.trans_group)) {
                                    this.trans_group['Combined Data'] = []
                                    // console.log("Hi!", this.Transactions[name])
                                    last_ndv = 0
                                    last_rewards = 0
                                } 
    
                                if(this.trans_group['Combined Data'].labels == null) {
                                    this.trans_group['Combined Data'] = {
                                        labels: [ datestring ],
                                        datasets: [{
                                            label: 'Combined Data'+" "+this.metric,
                                            backgroundColor: 'blue',
                                            borderColor: 'blue',
                                            data: [ total_metric ]
                                    }]
                                }
                                }
                                else {
                                    
                                    this.trans_group['Combined Data'].labels.push(datestring)
                                    //Figure out how to push to dataset.data.
                                    dataD = this.trans_group['Combined Data'].datasets[0].data
                                    dataD.push(total_metric)
                                    this.trans_group['Combined Data'].datasets["data"] = this.trans_group['Combined Data'].datasets["data"] + dataD
                                    dataD = []
                                }
                        }       

                        total_metric = Number(metric_value);
                    }

                    // if (show_wallets.includes(name) && name != undefined) {
                    
                    
              //      console.log(total_metric)
                    lastdate = datestring
                    
                    })
                })
                
                // console.log("hi", this.Transactions, trans_data)
            },

        }

  
  
}
</script>