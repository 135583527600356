<template>
  <div id="drip_view">
      <div class="container my-2">
        <div class="row p-2 pb-0 pe-lg-0 pt-lg-10 align-items-left rounded-3 border shadow-lg"> 
        <!-- Start Loop -->
        <div class="container col-xl-6 col-lg-6 col-md-6 mb-4 pt-4">
            <div v-for="item in teams" :key="item.name">
              <h1 style="align: left;">{{item.name}}</h1>
              <div   id="topStatsContainer" class="row">
                <div  class="container col-4 col-xl-4 col-lg-4 col-md-4 text-center">
                  <div>
                    <i class="bi bi-coin" style="font-size: 2rem; color: cornflowerblue;"></i>
                    <h5 class="mb-0 font-weight-semibold color-theme-1 mb-2">Available</h5>
                    <p class="text-large mb-2">
                      <span id="numbers">{{item.available}}</span>
                    </p>
                    <p class="text-small" id="usd">{{item.available_usd}}</p></div>
                  </div>
                <div  class="container col-4 col-xl-4 col-lg-4 col-md-4 text-center">
                  <div  class="price-top-part">
                    <i class="bi bi-bank2" style="font-size: 2rem; color: cornflowerblue;"></i>
                    <h5 class="mb-0 font-weight-semibold color-theme-1 mb-2">Deposits</h5>
                    <p class="text-large mb-2">
                      <span id="numbers">{{item.deposits}}</span></p>
                      <p class="text-small" id="usd">{{item.deposits_usd}}</p>
                  </div>
                </div>
                 <div  class="container col-4 col-xl-4 col-lg-4 col-md-4 text-center">
                      <div  class="price-top-part">
                        <i class="bi bi-people" style="font-size: 2rem; color: cornflowerblue;"></i>
                        <h5 class="mb-0 font-weight-semibold color-theme-1 mb-2">Team</h5>
                        <p class="text-large mb-2">
                          <span id="numbers">{{item.directs}}<br />{{item.total_team}} </span></p>
                      </div>
                    </div>
                  </div>
                <hr />
              </div>
            <!--End Loop-->
            
              <h1> Totals </h1>
              <div   id="topStatsContainer" class="row">
                <div  class="container col-4 col-xl-4 col-lg-4 col-md-4 text-center">
                  <div  class="price-top-part">
                    <p class="text-large mb-2">
                      <span id="numbers">{{totalArray[0].available}}</span></p>
                      <p class="text-small" id="usd">{{totalArray[0].available_usd}}</p>
                  </div>
                </div>
                <div  class="container col-4 col-xl-4 col-lg-4 col-md-4 text-center">
                  <div  class="price-top-part">
                    <p class="text-large mb-2">
                      <span id="numbers">{{totalArray[0].deposits}}</span></p>
                      <p class="text-small" id="usd">{{totalArray[0].deposits_usd}}</p>
                  </div>
                </div>
                <div  class="container col-4 col-xl-4 col-lg-4 col-md-4 text-center">
                  <div  class="price-top-part">
                    <p class="text-large mb-2">
                      <span id="numbers">{{totalArray[0].directs}}<br />{{totalArray[0].team}}</span></p>
                  </div>
                </div>
              </div>
              <hr />
              <div align-left id="numbers-small">Drip ${{drip_price}} | BNB ${{bnb_price}}</div><br />
              <div align-left id="numbers-small align-left">
              Daily: ${{daily}} - tax = ${{daily_tax}}<br />
              Weekend: ${{weekend}} - tax = ${{weekend_tax}}<br />
              Week: ${{week}} - tax = ${{week_tax}}<br />
              Month: ${{month}} - tax = ${{month_tax}}</div>
          </div>       
        </div>
    </div>
  </div>
</template>
<script>

import {
  getConnection,
  getUserInfo,
  claimsAvailable,
  getContract,
  getDripPrice
} from "../web3tools/Contract";
// import Header from "./Header";

import {
  convertDrip,
  formatCurrency
} from "../web3tools/utils";

export default {
  name: 'Emmett',
  async created() {
    if (localStorage.getItem("useTeam")) {
    this.useTeam = localStorage.getItem("useTeam");
    } else {
      this.useTeam = 2;
    }
    this.getUpline()
    this.interval = setInterval(() => this.getUpline(), 10000);
   window.scrollTo(0, 1);
  },
  async mounted() {
        this.teambutton()
  },
  data() {
    return {
      drip_price: '',
      bnb_price: '',
      br34p_price: '',
      teams: [],
      totals: {
        directs: 0,
        team: 0,
        available: 0,
        available_usd: 0,
        deposits: 0,
        deposits_usd: 0
      },
      totalArray: [{
        directs: 0,
        team: 0,
        available: 0,
        available_usd: 0,
        deposits: 0,
        deposits_usd: 0
      }],
      useTeam: 1,
      daily: 0,
      weekend: 0,
      week: 0,
      month: 0,
      daily_tax: 0,
      weekend_tax: 0,
      week_tax: 0,
      month_tax: 0
    }
  },
  methods: {
    teambutton() {
      if (this.useTeam == 1) {
          document.getElementById("teamname").innerHTML = "Emmett"
      } else {
          document.getElementById("teamname").innerHTML = "Emmett"
      }
    },
    switchTeams() {
      if (this.useTeam == 1) { 
        this.teams = []
        this.useTeam = 2;
        localStorage.setItem("useTeam", this.useTeam)
        this.teambutton()
      }
      else { 
        this.useTeam = 1; 
        this.teams=[]
        localStorage.setItem("useTeam", this.useTeam)
        this.teambutton()
      }
      this.totals = {directs: 0,
        team: 0,
        available: 0,
        available_usd: 0,
        deposits: 0,
        deposits_usd: 0};
      this.getUpline()

    },
    async getUpline() {

      let JCteamAddresses = {
        "Bob": "0x392ba366ce22e7714f10857bb34c82348cd367b0",
        "Riker": "0x446c251ef29489c3158585b9f551467d3a5bcf37",
        "Bill": "0x4b19e3d8c32f6ef74efcb453a52ec5a7676a8abb",
        "Milo": "0xe17a251ac400c7785f3d65a063023b1700ea81f4",
        "Mario": "0x25696806259971f1e177f5641d13e45f1101acc8",
        "Marvin": "0x6f3f75bdb23261bfa637fa202111ebf70acb7018",
        "Luke": "0xecea95acc9d4f4ce7e32d284f78b3efe7c7a1b80",
        "Bender": "0xd299f04a4b616b93656f604278effce4000b9fde",
        "Homer": "0x66496773d6c34d95c8934c8fac7370363081c8ca",
        "Erland": "0xe0c4cf8c2bbcb01b3a3623dfbce26353799583f3",
        "Flaquirucha": "0x4eded08ebf7b3e2d312bdc35da2244a374fadec6"
      }
      

      
      let teamWallets = JCteamAddresses
      

        let web3 = web3 ?? (await getConnection());
        let contract = contract ?? (await getContract(web3));
          
        let [bnbPrice, dripPriceRaw] = await getDripPrice(web3);
        let currentDripPrice = dripPriceRaw * bnbPrice;

        this.bnb_price = bnbPrice;
        this.drip_price = formatCurrency(convertDrip(currentDripPrice));
        
        let arrayKey = 0;
        this.totals = {
        directs: 0,
        team: 0,
        available: 0,
        available_usd: 0,
        deposits: 0,
        deposits_usd: 0
      }
        for (const [key, value] of Object.entries(teamWallets)) {
          let userInfo = await getUserInfo(contract, value);
          let walletclaimsAvailable = await claimsAvailable(contract, value);
       
          let team_data = {
            id: value,
            name: key,
            userinfo: userInfo,
            available: Number(convertDrip(walletclaimsAvailable).toLocaleString()),
            available_usd: "$" + Number(formatCurrency(convertDrip(walletclaimsAvailable) * formatCurrency(convertDrip(currentDripPrice)))).toLocaleString(),
            deposits: Number(convertDrip(userInfo.deposits)).toLocaleString(),
            deposits_usd: "$" + Number(formatCurrency(convertDrip(userInfo.deposits) * formatCurrency(convertDrip(currentDripPrice)))).toLocaleString(),
            directs: userInfo.referrals,
            total_team: userInfo.total_structure  
          }
       
            // Add to totals
            this.totals.directs = +this.totals.directs + +userInfo.referrals;
            this.totals.team = +this.totals.team + +userInfo.total_structure;
            this.totals.deposits = +this.totals.deposits + +convertDrip(userInfo.deposits);
            this.totals.available = +this.totals.available + +convertDrip(walletclaimsAvailable);
       
          this.teams.splice(arrayKey, 1, team_data)
          arrayKey++;
        }
        
        this.totals.available_usd = "$" + Number(formatCurrency(this.totals.available * formatCurrency(convertDrip(currentDripPrice)))).toLocaleString();
        this.totals.deposits_usd = "$" + Number(formatCurrency(+this.totals.deposits * formatCurrency(convertDrip(currentDripPrice)))).toLocaleString();
        
        let daily_calc = ((this.totals.deposits * .01) * formatCurrency(convertDrip(currentDripPrice)))
        this.weekend = Number(formatCurrency((daily_calc * 3))).toLocaleString()
        this.week = Number(formatCurrency((daily_calc * 7))).toLocaleString()
        this.month = Number(formatCurrency((daily_calc * 30.3))).toLocaleString()
        
        this.weekend_tax = (Number(formatCurrency((daily_calc * 3))) - (Number(formatCurrency((daily_calc * 3)))*.19)).toLocaleString()
        this.week_tax = (Number(formatCurrency((daily_calc * 7))) - (Number(formatCurrency((daily_calc * 7)))*.19)).toLocaleString()
        this.month_tax = (Number(formatCurrency((daily_calc * 30.3))) - (Number(formatCurrency((daily_calc * 30.3)))*.19)).toLocaleString()
        this.daily_tax = (Number(formatCurrency(daily_calc)) - (Number(formatCurrency(daily_calc))*.19)).toLocaleString()
        this.daily = Number(formatCurrency(daily_calc)).toLocaleString().toLocaleString()
        
        
        this.totals.available = Number(this.totals.available).toLocaleString()
        this.totals.deposits = Number(this.totals.deposits).toLocaleString()

        // console.log(this.daily)
        
        
        this.totalArray.splice(0,1,this.totals)

    }
  }
}
</script>
<style>
#drip_view {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  /*margin-bottom: 60px;*/
}
#numbers {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  font-size: 25px;
  font-weight: bold;
}
#numbers-small {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  font-size: 20px;
  font-weight: bold;
}
#usd {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: teal;
}
</style>