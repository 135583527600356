import { createWebHistory, createRouter } from "vue-router";

const routes = [
  {
    path: '/',
    name: 'Dashboard',
    component: () => import('../components/JCCrypto')
  },
  {
    path: '/report',
    name: 'Report',
    component: () => import('../components/Report')
  },  
  {
    path: '/stampedereport',
    name: 'StampedeReport',
    component: () => import('../components/StampedeReport')
  },  
  {
    path: '/trunkreport',
    name: 'TrunkReport',
    component: () => import('../components/TrunkReport')
  },
  {
    path: '/pelton',
    name: 'Pelton',
    component: () => import('../components/Pelton')
  },    
  {
    path: '/emmett',
    name: 'Emmett',
    component: () => import('../components/Emmett')
  },  
  {
    path: '/charts',
    name: 'charts',
    component: () => import('../components/charts')
  },  
  {
    path: '/charts_combined',
    name: 'charts_combined',
    component: () => import('../components/charts_combined')
  },  
  {
    path: '/charts_dad',
    name: 'charts_dad',
    component: () => import('../components/charts_dad')
  },
  {
    path: '/family',
    name: 'family',
    component: () => import('../components/family')
  },
  {
    path: '/familycharts',
    name: 'familycharts',
    component: () => import('../components/familycharts')
  },

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router;