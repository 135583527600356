<template>
    <div class="row">
        <div class="col-md-12">
            <h1>Trunk Report</h1>
            <table class="table table-striped">
                <thead>
                    <tr>
                        <th>Date</th>
                        <th>Name</th>
                        <th>Action</th>
                        <th>Deposits</th>
                        <th>Amount</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="tr in Transactions" :key="tr.key">
                        <td>{{ tr.date }}</td>
                        <td>{{ tr.name }}</td>
                        <td>{{ tr.action }}</td>
                        <td>{{ tr.deposits }}</td>
                        <td>{{ tr.available }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
    import { db } from '../firebaseDb';
    export default {
        data() {
            return {
                Transactions: [],
            }
        },
        created() {
            this.getData();
        },
        methods: {
            getData() {
                const trans_group = [];
                var trans_data = {}
                db.collection('TrunkTransactions').orderBy('name').orderBy('date', 'asc').onSnapshot((snapshotChange) => {
                this.Transactions = [];
                snapshotChange.forEach((doc) => {
                    console.log(doc)
                    let date = new Date(doc.data().date)
                    let month = new Date(doc.data().date).getMonth() + 1;
                    let datestring = date.getFullYear() + "/" + month + "/" + date.getDate() + " " + date.getHours() + ":" + date.getMinutes()
                    let name = doc.data().name
                    // console.log("name" in this.Transactions)
                    
                    if (!(name in trans_group)) {
                        trans_group[name] = []
                        // console.log("Hi!", this.Transactions[name])
                    } 
                    

                     trans_data = {
                        key: doc.id,
                        name: doc.data().name,
                        date: datestring,
                        action: doc.data().action,
                        deposits: doc.data().deposits,
                        available: doc.data().available,
                        rewards: doc.data().rewards,
                    };
                    
                    
                    this.Transactions.push(trans_data);
                        //  console.log(date, this.Transactions[name])
                    
                    })
                })
                
                console.log("hi", this.Transactions, trans_data)
            },

        }
    }
</script>

<style>
    .btn-primary {
        margin-right: 12px;
    }
</style>