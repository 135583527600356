<template>
  <JCCrypto v-if="this.$route.name == 'Dashboard'"/>
  <Report v-if="this.$route.name == 'Report'"/>
  <StampedeReport v-if="this.$route.name == 'StampedeReport'"/>  
  <TrunkReport v-if="this.$route.name == 'TrunkReport'"/>  
  <Pelton v-if="this.$route.name == 'Pelton'"/>  
  <Emmett v-if="this.$route.name == 'Emmett'"/>  
  <charts v-if="this.$route.name == 'charts'"/>  
  <charts_combined v-if="this.$route.name == 'charts_combined'"/>  
  <charts_dad v-if="this.$route.name == 'charts_dad'"/>  
  <family v-if="this.$route.name == 'family'"/>  
  <familycharts v-if="this.$route.name == 'familycharts'"/>  
</template>

<script>
import JCCrypto from './components/JCCrypto.vue'
import Report from './components/Report.vue'
import StampedeReport from './components/StampedeReport.vue'
import TrunkReport from './components/TrunkReport.vue'
import Pelton from './components/Pelton.vue'
import Emmett from './components/Emmett.vue'
import charts from './components/charts.vue'
import charts_combined from './components/charts_combined.vue'
import charts_dad from './components/charts_dad.vue'
import family from './components/family.vue'
import familycharts from './components/familycharts.vue'

export default {
  name: 'App',
  components: {
    JCCrypto,
    Report,
    StampedeReport,
    TrunkReport,
    Pelton,
    Emmett,
    charts,
    charts_combined,
    charts_dad,
    family,
    familycharts,
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 0px;
}
</style>
