<template>
    <div class="row">
        <div class="col-md-12">
            <h1>DRIP Report</h1>
            <table class="table table-striped">
                <thead>
                    <tr>
                        <th>Date</th>
                        <th>Name</th>
                        <th>Action</th>
                        <th>Deposits</th>
                        <th>Amount</th>
                        <th>Rewards</th>
                        <th>Airdrops</th>
                        <th>Team</th>
                        <th>NDV</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="tr in Transactions" :key="tr.key">
                        <td>{{ tr.date }}</td>
                        <td>{{ tr.name }}</td>
                        <td>{{ tr.action }}</td>
                        <td>{{ tr.deposits }}</td>
                        <td>{{ tr.available }}</td>
                        <td>{{ tr.rewards }} ({{ tr.rewards_diff }})</td>
                        <td>{{ tr.airdrop_sent }}/{{ tr.airdrop_received }}</td>
                        <td>{{ tr.directs }}/{{tr.total_team}}</td>
                        <td>{{ tr.ndv }} ({{ tr.ndv_diff }})</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
    import { db } from '../firebaseDb';
    export default {
        data() {
            return {
                Transactions: [],
            }
        },
        created() {
            this.getData();
        },
        methods: {
            getData() {
                const trans_group = [];
                var trans_data = {}
                var last_ndv = 0
                var last_rewards = 0
                var ndv_diff = 0
                var rewards_diff = 0
                db.collection('Transactions').orderBy('name').orderBy('date', 'asc').onSnapshot((snapshotChange) => {
                this.Transactions = [];
                snapshotChange.forEach((doc) => {
                    
                    let date = new Date(doc.data().date)
                    let month = new Date(doc.data().date).getMonth() + 1;
                    let datestring = date.getFullYear() + "/" + month + "/" + date.getDate() + " " + date.getHours() + ":" + date.getMinutes()
                    let name = doc.data().name
                    // console.log("name" in this.Transactions)
                    
                    if (!(name in trans_group)) {
                        trans_group[name] = []
                        // console.log("Hi!", this.Transactions[name])
                        last_ndv = 0
                        last_rewards = 0
                    } 

                    
                    ndv_diff = doc.data().ndv - last_ndv
                    rewards_diff = doc.data().rewards - last_rewards
                    
                    ndv_diff = ndv_diff.toLocaleString()
                    rewards_diff = rewards_diff.toLocaleString()

                   
                    
                     trans_data = {
                        key: doc.id,
                        name: doc.data().name,
                        date: datestring,
                        action: doc.data().action,
                        deposits: doc.data().deposits,
                        available: doc.data().available,
                        rewards: doc.data().rewards,
                        airdrop_sent: doc.data().airdrop_sent,
                        airdrop_received: doc.data().airdrop_received,
                        directs: doc.data().directs,
                        total_team: doc.data().total_team,
                        ndv: doc.data().ndv,
                        ndv_diff: ndv_diff,
                        rewards_diff: rewards_diff
                    };
                    
                                        
                    last_ndv = doc.data().ndv
                    last_rewards = doc.data().rewards
                    
                    
                    this.Transactions.push(trans_data);
                        //  console.log(date, this.Transactions[name])
                    
                    })
                })
                
                console.log("hi", this.Transactions, trans_data)
            },

        }
    }
</script>

<style>
    .btn-primary {
        margin-right: 12px;
    }
</style>