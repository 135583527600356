import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

const firebaseConfig = {
 apiKey: "AIzaSyAVOkgZIpzaJCjhgoP-zZLDDDec4jHzjMQ",
  authDomain: "jccrypto-d64b5.firebaseapp.com",
  projectId: "jccrypto-d64b5",
  storageBucket: "jccrypto-d64b5.appspot.com",
  messagingSenderId: "669053577168",
  appId: "1:669053577168:web:c8c72b8480af162b87fbd5",
}

const firebaseApp = firebase.initializeApp(firebaseConfig);

export const db = firebaseApp.firestore();