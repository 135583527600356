<template>
  <div id="drip_view">
      <div class="container my-2">
        <div class="row p-2 pb-0 pe-lg-0 pt-lg-10 align-items-left rounded-3 border shadow-lg"> 
        <!-- Start Loop -->
        <div class="container col-xl-6 col-lg-6 col-md-6 mb-4 pt-4">
            <div v-for="item in teams" :key="item.name">
              <h1 style="align: left;">{{item.name}}</h1>
              <div   id="topStatsContainer" class="row">
                <div  class="container col-4 col-xl-4 col-lg-4 col-md-4 text-center">
                  <div>
                    <i class="bi bi-coin" style="font-size: 2rem; color: cornflowerblue;"></i>
                    <h5 class="mb-0 font-weight-semibold color-theme-1 mb-2">Available</h5>
                    <p class="text-large mb-2">
                      <span id="numbers">{{item.available}}</span>
                    </p>
                    <p class="text-small" id="usd">{{item.available_usd}}</p></div>
                  </div>
                <div  class="container col-4 col-xl-4 col-lg-4 col-md-4 text-center">
                  <div  class="price-top-part">
                    <i class="bi bi-bank2" style="font-size: 2rem; color: cornflowerblue;"></i>
                    <h5 class="mb-0 font-weight-semibold color-theme-1 mb-2">Deposits</h5>
                    <p class="text-large mb-2">
                      <span id="numbers">{{item.deposits}}</span></p>
                      <p class="text-small" id="usd">{{item.deposits_usd}}</p>
                  </div>
                </div>
                 <div  class="container col-4 col-xl-4 col-lg-4 col-md-4 text-center">
                      <div  class="price-top-part">
                        <i class="bi bi-people" style="font-size: 2rem; color: cornflowerblue;"></i>
                        <h5 class="mb-0 font-weight-semibold color-theme-1 mb-2">Team</h5>
                        <p class="text-large mb-2">
                          <span id="numbers">{{item.directs}}<br />{{item.total_team}} </span></p>
                      </div>
                    </div>
                  </div>
                <hr />
              </div>
            <!--End Loop-->
            
              <h1> Totals </h1>
              <div   id="topStatsContainer" class="row">
                <div  class="container col-4 col-xl-4 col-lg-4 col-md-4 text-center">
                  <div  class="price-top-part">
                    <p class="text-large mb-2">
                      <span id="numbers">{{totalArray[0].available}}</span></p>
                      <p class="text-small" id="usd">{{totalArray[0].available_usd}}</p>
                  </div>
                </div>
                <div  class="container col-4 col-xl-4 col-lg-4 col-md-4 text-center">
                  <div  class="price-top-part">
                    <p class="text-large mb-2">
                      <span id="numbers">{{totalArray[0].deposits}}</span></p>
                      <p class="text-small" id="usd">{{totalArray[0].deposits_usd}}</p>
                  </div>
                </div>
                <div  class="container col-4 col-xl-4 col-lg-4 col-md-4 text-center">
                  <div  class="price-top-part">
                    <p class="text-large mb-2">
                      <span id="numbers">{{totalArray[0].directs}}<br />{{totalArray[0].team}}</span></p>
                  </div>
                </div>
              </div>
              <hr />
              <div align-left id="numbers-small">Drip ${{drip_price}} | BNB ${{bnb_price}}</div><br />
              <div align-left id="numbers-small align-left">
              Daily: ${{daily}} - tax = ${{daily_tax}}<br />
              Weekend: ${{weekend}} - tax = ${{weekend_tax}}<br />
              Week: ${{week}} - tax = ${{week_tax}}<br />
              Month: ${{month}} - tax = ${{month_tax}}</div>
          </div>       
        </div>
    </div>
    <button @click="switchTeams()">Switch to <span id="teamname"></span></button>
  </div>
</template>
<script>

import {
  getConnection,
  getUserInfo,
  claimsAvailable,
  getContract,
  getDripPrice
} from "../web3tools/Contract";
// import Header from "./Header";

import {
  convertDrip,
  formatCurrency
} from "../web3tools/utils";

export default {
  name: 'Pelton',
  async created() {
    if (localStorage.getItem("useTeam")) {
    this.useTeam = localStorage.getItem("useTeam");
    } else {
      this.useTeam = 2;
    }
    this.getUpline()
    this.interval = setInterval(() => this.getUpline(), 10000);
   window.scrollTo(0, 1);
  },
  async mounted() {
        this.teambutton()
  },
  data() {
    return {
      drip_price: '',
      bnb_price: '',
      br34p_price: '',
      teams: [],
      totals: {
        directs: 0,
        team: 0,
        available: 0,
        available_usd: 0,
        deposits: 0,
        deposits_usd: 0
      },
      totalArray: [{
        directs: 0,
        team: 0,
        available: 0,
        available_usd: 0,
        deposits: 0,
        deposits_usd: 0
      }],
      useTeam: 1,
      daily: 0,
      weekend: 0,
      week: 0,
      month: 0,
      daily_tax: 0,
      weekend_tax: 0,
      week_tax: 0,
      month_tax: 0
    }
  },
  methods: {
    teambutton() {
      if (this.useTeam == 1) {
          document.getElementById("teamname").innerHTML = "Pelton"
      } else {
          document.getElementById("teamname").innerHTML = "JCCrypto"
      }
    },
    switchTeams() {
      if (this.useTeam == 1) { 
        this.teams = []
        this.useTeam = 2;
        localStorage.setItem("useTeam", this.useTeam)
        this.teambutton()
      }
      else { 
        this.useTeam = 1; 
        this.teams=[]
        localStorage.setItem("useTeam", this.useTeam)
        this.teambutton()
      }
      this.totals = {directs: 0,
        team: 0,
        available: 0,
        available_usd: 0,
        deposits: 0,
        deposits_usd: 0};
      this.getUpline()

    },
    async getUpline() {

      let JCteamAddresses = {
        "JCCrypto Team 1": "0x8a9281ECEcE9b599C2f42d829C3d0d8e74b7083e",
        "JCCrypto Team 2": "0x861A3193Cbc4f186596B4B5bb704D3CE1Bc4F3B7",
        "JCCrypto Team 3": "0x8Febd6ab3a69e271992AbA6F8358CD83C57BB47f",
        "JCCrypto 10K": "0x69B7959Bb3C8689b74A73dd2e04EfAa213724c74",
        "JCCrypto 100k Parent": "0x8d29bC88AcfE820646a7222ae1E96ef0E0c424FB",
        "JCCrypto 50k 1": "0xB9232124b0489DA5CDF6f4516f8c2c99DA9b4e55",
        "JCCrypto 50K 2": "0x9B5d99a19451a614685FD75DEE6B22520b9698B7",
        "JCCrypto 1K Airdrop": "0x9b486Dc9Bbe16470A3B8c37eFDa543bE9D297c6C"
      }
      
      let JamesteamAddresses = {
        "Pelton Team 1": "0x5aca27AEe06a4ac9c839415271Bbc4558f8b7679",
        "Pelton Team 2": "0x4DDe991518F617bcB5d2399Bc5d7CeB82eafC65D",
        "Pelton Team 3": "0x8aB1EF55810ca9fC1AB329f95775E5BFf656935B"
      }
      
      let teamWallets = JCteamAddresses
      
      if (this.useTeam==1) {
        teamWallets = JCteamAddresses;
      } else if(this.useTeam == 2) {
        teamWallets = {}
        teamWallets = JamesteamAddresses;
      } 

        let web3 = web3 ?? (await getConnection());
        let contract = contract ?? (await getContract(web3));
          
        let [bnbPrice, dripPriceRaw] = await getDripPrice(web3);
        let currentDripPrice = dripPriceRaw * bnbPrice;

        this.bnb_price = bnbPrice;
        this.drip_price = formatCurrency(convertDrip(currentDripPrice));
        
        let arrayKey = 0;
        this.totals = {
        directs: 0,
        team: 0,
        available: 0,
        available_usd: 0,
        deposits: 0,
        deposits_usd: 0
      }
        for (const [key, value] of Object.entries(teamWallets)) {
          let userInfo = await getUserInfo(contract, value);
          let walletclaimsAvailable = await claimsAvailable(contract, value);
       
          let team_data = {
            id: value,
            name: key,
            userinfo: userInfo,
            available: Number(convertDrip(walletclaimsAvailable).toLocaleString()),
            available_usd: "$" + Number(formatCurrency(convertDrip(walletclaimsAvailable) * formatCurrency(convertDrip(currentDripPrice)))).toLocaleString(),
            deposits: Number(convertDrip(userInfo.deposits)).toLocaleString(),
            deposits_usd: "$" + Number(formatCurrency(convertDrip(userInfo.deposits) * formatCurrency(convertDrip(currentDripPrice)))).toLocaleString(),
            directs: userInfo.referrals,
            total_team: userInfo.total_structure  
          }
       
            // Add to totals
            this.totals.directs = +this.totals.directs + +userInfo.referrals;
            this.totals.team = +this.totals.team + +userInfo.total_structure;
            this.totals.deposits = +this.totals.deposits + +convertDrip(userInfo.deposits);
            this.totals.available = +this.totals.available + +convertDrip(walletclaimsAvailable);
       
          this.teams.splice(arrayKey, 1, team_data)
          arrayKey++;
        }
        
        this.totals.available_usd = "$" + Number(formatCurrency(this.totals.available * formatCurrency(convertDrip(currentDripPrice)))).toLocaleString();
        this.totals.deposits_usd = "$" + Number(formatCurrency(+this.totals.deposits * formatCurrency(convertDrip(currentDripPrice)))).toLocaleString();
        
        let daily_calc = ((this.totals.deposits * .01) * formatCurrency(convertDrip(currentDripPrice)))
        this.weekend = Number(formatCurrency((daily_calc * 3))).toLocaleString()
        this.week = Number(formatCurrency((daily_calc * 7))).toLocaleString()
        this.month = Number(formatCurrency((daily_calc * 30.3))).toLocaleString()
        
        this.weekend_tax = (Number(formatCurrency((daily_calc * 3))) - (Number(formatCurrency((daily_calc * 3)))*.19)).toLocaleString()
        this.week_tax = (Number(formatCurrency((daily_calc * 7))) - (Number(formatCurrency((daily_calc * 7)))*.19)).toLocaleString()
        this.month_tax = (Number(formatCurrency((daily_calc * 30.3))) - (Number(formatCurrency((daily_calc * 30.3)))*.19)).toLocaleString()
        this.daily_tax = (Number(formatCurrency(daily_calc)) - (Number(formatCurrency(daily_calc))*.19)).toLocaleString()
        this.daily = Number(formatCurrency(daily_calc)).toLocaleString().toLocaleString()
        
        
        this.totals.available = Number(this.totals.available).toLocaleString()
        this.totals.deposits = Number(this.totals.deposits).toLocaleString()

        // console.log(this.daily)
        
        
        this.totalArray.splice(0,1,this.totals)

    }
  }
}
</script>
<style>
#drip_view {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  /*margin-bottom: 60px;*/
}
#numbers {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  font-size: 25px;
  font-weight: bold;
}
#numbers-small {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  font-size: 20px;
  font-weight: bold;
}
#usd {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: teal;
}
</style>